<template>
  <v-container fill-height>
    <div v-if="!shifts.finishedLoadingShifts" class="max-width-100">
      <v-flex xs12>
        <v-layout class="ma-0">
          <v-icon class="display-3 mr-2">mdi-lock</v-icon>
          <v-flex>
            <v-layout row wrap class="ma-0">
              <v-flex xs12 class="display-1 my-1 grey--text text--darken-1">
                {{$t('shifts.required_shift.title')}}
              </v-flex>
              <v-flex xs12 class="title mt-1 mb-8 grey--text text--darken-1">
                {{$t('shifts.loading')}}<loading-dots />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </div>
    <div v-else-if="!operatorShift && !printerShift" class="max-width-100">
      <v-flex xs12>
        <v-layout class="ma-0">
          <v-icon class="display-3 mr-2">mdi-lock</v-icon>
          <v-flex>
            <v-layout row wrap class="ma-0">
              <v-flex xs12 class="display-1 my-1 grey--text text--darken-1">
                {{$t('shifts.required_shift.title')}}
              </v-flex>
              <v-flex xs12 class="title my-1 grey--text text--darken-1">
                {{$t('shifts.required_shift.open_shift_required')}}
              </v-flex>
              <v-flex xs12 class="my-1">
                <v-btn @click="openShift()" :disabled="notConfigured" color="primary" outlined v-if="!operatorShift && !printerShift">{{$t('shifts.actions.open')}}</v-btn>
              </v-flex>
              <no-configuration-alert :show="notConfigured" class="my-1" />
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </div>
    <div v-else-if="unmatchingShifts" class="max-width-100">
      <v-flex xs12>
        <v-layout class="ma-0">
          <v-icon class="display-3 mr-2">mdi-lock</v-icon>
          <v-flex>
            <div class="display-1 my-1 grey--text text--darken-1">{{$t('shifts.required_shift.title')}}</div>
            <!-- La impresora tiene un turno abierto para otro operario -->
            <template v-if="printerShift">
              <div class="title my-1 grey--text text--darken-1">{{$t('shifts.errors.device_has_active_shift')}}</div>
              <v-btn small color="primary" outlined class="mb-2" @click="closeShift(printerShift.id)">{{$t('shifts.actions.close_printer')}}</v-btn>
            </template>
            <!-- El operario tiene un turno abierto en una impresora que no coincide con la actual -->
            <template v-else-if="operatorShift">
              <div class="title my-1 grey--text text--darken-1">{{$t('shifts.errors.operator_has_active_shift', { device: externalPrinterName })}}</div>
              <v-btn small color="primary" outlined v-if="operatorShift" class="mb-2" @click="closeShift(operatorShift.id)">{{$t('shifts.actions.close_operator') }}</v-btn>
            </template>
          </v-flex>
        </v-layout>
      </v-flex>
    </div>
    <div v-else :key="reloadView" class="max-width-100">
      <slot />
    </div>
    <supervisor-modal :triggerOpen="openSupervisorModal" @finished="continueProcess()"/>
    <open-shift-modal :triggerOpen="openShiftModal"/>
    <close-shift-modal :triggerOpen="closeShiftModal" :shiftId="closingShiftId" inNavigationMenu />
    <confirm-modal
      :title="!unmatchingShifts ? $t('shifts.actions.close') : printerShift ? $t('shifts.actions.close_printer') : $t('shifts.actions.close_operator')"
      :triggerOpen="confirmCloseShift"
      @accept="continueProcess()"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import shiftMixins from '@/mixins/Shifts'
export default {
  data () {
    return {
      reloadView: null
    }
  },
  mixins: [
    shiftMixins
  ],
  components: {
    SupervisorModal: () => import('@/components/view-components/modals/SupervisorModal.vue'),
    OpenShiftModal: () => import('@/components/view-components/modals/OpenShiftModal.vue'),
    ConfirmModal: () => import('@/components/app-components/modals/ConfirmModal.vue'),
    CloseShiftModal: () => import('@/components/view-components/modals/CloseShiftModal.vue'),
    NoConfigurationAlert: () => import('@/components/view-components/AlertNoConfiguration'),
    LoadingDots: () => import('@/components/app-components/LoadingDots.vue')
  },
  watch: {
    operatorShift () {
      this.reloadView = Date.now()
    },
    printerShift () {
      this.reloadView = Date.now()
    }
  }
}
</script>